.icon {
  transition: transform .7s ease-in-out;
  pointer-events: auto;
  padding: 6px;
  filter: drop-shadow(0em 0em .3em rgba(255, 255, 255, 0.7));
  width: 3.5em;
}

.iconorange {
  transition: transform .7s ease-in-out;
  pointer-events: auto;
  padding: 6px;
  filter: drop-shadow(0em 0em .3em #ED7E48);
  width: 3.5em;
}

.iconorange:hover {
  transform: rotate(360deg);
  cursor: pointer;
}

.icon:hover {
  transform: rotate(360deg);
  cursor: pointer;
}

html {
  margin: 0;
  padding: 0;
  max-height: 100vh;
  min-height: 100vh;
  height:100vh;
  max-width: 100vw;
  position:fixed;
  overflow: hidden;
}

h1 {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position:fixed;
  background-color: transparent; /* rgb(245,245,250) */
}

canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

#particles canvas{
  position: absolute;
  width: 100%;
  height: 100%;
}

.img{
  font-family: 'Nunito';
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: white;
  text-shadow: 0em 0em .3em #ffffff;
}

#logo {
  margin: 0 auto;
  padding: 12px;
  width: 100%;  
  pointer-events: none;
}

#quote {
  font-family: 'Nunito';
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: white;
  text-shadow: 0em 0em .3em #ffffff;
  width:100%;
  pointer-events: auto;
  transform: translate(0%, -.3em);
}

#buttons {
  flex: 1 1 33%
}

/* Make entire app resizable */
:root {
  font-size: 1vw;
}

.app{
  pointer-events: none;
  display: flex;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li{
  display:inline-block;
}

.container hr {
  width: 100%;
  content: '';
  height: 0; 
  margin: 0; 
  border: 0; 
}

.centercontainer{
  /* max-height:300px; */
  width:100%;
}

@font-face {
  font-family: 'SourceCodePro';
  src: local('SourceCodePro'), url("Fonts/SourceCodePro/Sauce\ Code\ Pro\ Nerd\ Font\ Complete\ Mono.ttf") format('truetype');
}

@font-face {
  font-family: 'Arimo';
  src: local('Arimo'), url("Fonts/Arimo\ Regular\ Nerd\ Font\ Complete.ttf") format('truetype');
}


.CodeMirror {
  margin: 0;
  padding: 0;
  width:100%;
  /* max-height: 300px; */
  font-size: 4.5em;
  overflow: auto;
  max-height: 256px;
  font-family:'SourceCodePro';
  color:black;
  border-radius: 16px;
}
.CodeMirror *{
  font-family:'SourceCodePro';
}

/* .ͼ2 .cm-activeLine {
   background-color: rgb(248,248,248);   
} */


/* Text Decorators */
underkey{
  text-decoration: underline;
}

b{
  color:hsl(0, 0%, 40%);
}

tooltip{
  color:darkgray;
  font-size: .6em;
  vertical-align:middle;
  /* bottom: 20px; */
}

rainbow{
  background-image: linear-gradient(to left, hsl(300, 76%, 75%), hsl(275, 100%, 70%), hsl(240, 100%, 75%), hsl(120, 100%, 70%), hsl(60, 100%, 55%), hsl(39, 100%, 65%), hsl(0, 100%, 75%), hsl(0, 100%, 75%));
  background-clip: text;
  color: transparent;
} 
munder{
  text-decoration: underline;
  color: hsl(0, 100%, 87%);
}

arrow{
  color:gray;
  font-size: .9em;
  vertical-align:middle;
}
units{
  color:gray;
}

.centerdock { 
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  /* transform: translate(0%, -8%); */
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width:98%;
}

.button {
  font-family: 'Arimo';
  font-size:1.5em;
  border-radius: 16px;
  background-color: white;
  color: rgb(50,50,50);
  padding:8px;
  padding-left:16px;
  padding-right:16px;
  margin:4px;
  border:none;
  z-index: 1;
}

.button:hover {
  background-color: rgb(248,248,248);
  cursor:pointer;
}


/* Special Buttons */
#go{
  background-color: rgb(248,255,248);
}
#delete{
  background-color: rgb(255, 248, 248);
}
#highlight{
  background-color: rgb(255,255,245);
}
#go:hover{
  background-color: rgb(238,255,238);
}
#highlight:hover{
  background-color: rgb(255,255,233);
}

.timings{
  text-align: left;
  align-content: bottom;
  font-size: 1em;
  font-family: 'Arimo';
  display: inline-block;
  vertical-align: middle;
}

#timingvariables{
  color:rgb(50,50,50);
  cursor:help;
}

#timinggo{
  color:rgb(50,50,50);
  cursor:help;
}

#timingvariables:hover:after{
  cursor:help;
  content:": Time to gather variables";
  position: absolute;
  color:gray;
}
#timinggo:hover:after{
  cursor:help;
  content:": Time to run cell";
  position: absolute;
  color:gray;
}

/* #functions{
  overflow-y: hidden;
} */

/* DropMenus */
.dropbtn {
  font-family: 'Arimo';
  font-size:1.5em;
  border-radius: 16px;
  background-color: white;
  color: rgb(50,50,50);
  padding:8px;
  padding-left:16px;
  padding-right:16px;
  margin:4px;
  border:none;
  position: relative; /*allows you to place child menu behind the button(which is parent)*/
  /* z-index: 2; */
}


.dropup {
  position: relative;
  display: inline-block;
  font-family: 'Arimo';
  
}

.dropup-content {
  display: none;
  position: absolute;
  background-color: rgb(250,250,250);
  width:95%; /*shrink to better align with button right*/
  bottom: 35px;
  z-index: 1;
  scroll-behavior: auto;
  max-height: 100px;
  overflow:scroll;
  font-family: 'Arimo';
  font-size:1.25em;
  text-align: right;
  border-radius: 16px;
  overflow-x: hidden;
  /* z-index: 1; */
  left:4px; /*move left to align with button left*/
}

/*Special Settings*/
/* #settingsdropupcontent{
  width:95%;
  text-align:left;
}
#settingsdropupcontent a{
  margin-left:.2em;
} */

.dropup-content a {
  color: rgb(120,120,120);
  padding:2px;
  text-decoration: none;
  display: block;
}

.dropup-content a:hover {
  background-color: rgb(245,245,245);  
  color: rgb(40,40,40);
}

.dropup:hover .dropup-content {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: rgb(235,235,235);
}

.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Arimo';
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(250,250,250);
  width:95%;
  z-index: 1;
  scroll-behavior: auto;
  max-height: 100px;
  overflow:scroll;
  font-family: 'Arimo';
  font-size:1.25em;
  text-align: right;
  border-radius: 16px;
  overflow-x: hidden;
  top:35px;
  left:4px;
}
.dropdown-content a {
  color: rgb(120,120,120);
  padding:2px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: rgb(245,245,245);  
  color: rgb(40,40,40);
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: rgb(235,235,235);
}






.rotatingtext {
  float: left;
  overflow: hidden;
  position: relative;
  height: 1em;
}
.rotatingspan {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  animation: move 5s;
  top:0;
  /*animation-iteration-count: infinite;*/
  animation-delay: 1s;
  text-align:right;
  transition-timing-function: ease-in;
}

@keyframes move {
0%  { top: 0px; }
15% { top: -1.125em }
30% { top: -2.25em; }
45% { top: -3.375em; }
60% { top: -4.5em; }
75% { top: -5.625em; }
}




.rw-words{
	display: inline;
	/* text-indent: 10px; */
}
/* .rw-words-1 span{
	position: absolute;
	opacity: 0;
	overflow: hidden;
  text-align:right;
  display:block;
	-webkit-animation: rotateWord 18s linear infinite 0s;
	-ms-animation: rotateWord 18s linear infinite 0s;
	animation: rotateWord 18s linear infinite 0s;
}
.rw-words-1 span:nth-child(2) { 
    -webkit-animation-delay: 3s; 
	-ms-animation-delay: 3s; 
	animation-delay: 3s; 
}
.rw-words-1 span:nth-child(3) { 
    -webkit-animation-delay: 6s; 
	-ms-animation-delay: 6s; 
	animation-delay: 6s; 	
}
.rw-words-1 span:nth-child(4) { 
    -webkit-animation-delay: 9s; 
	-ms-animation-delay: 9s; 
	animation-delay: 9s; 
}
.rw-words-1 span:nth-child(5) { 
    -webkit-animation-delay: 12s; 
	-ms-animation-delay: 12s; 
	animation-delay: 12s; 
}
.rw-words-1 span:nth-child(6) { 
    -webkit-animation-delay: 15s; 
	-ms-animation-delay: 15s; 
	animation-delay: 15s; 
}
@-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); }
	5% { opacity: 1; -webkit-transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); }
	20% { opacity: 0; -webkit-transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
}
@-ms-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -ms-transform: translateY(-30px); }
	5% { opacity: 1; -ms-transform: translateY(0px);}
    17% { opacity: 1; -ms-transform: translateY(0px); }
	20% { opacity: 0; -ms-transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); transform: translateY(-30px); }
	5% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
	20% { opacity: 0; -webkit-transform: translateY(30px); transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
} */

/* 
#a1{
  transform:rotate(180deg);
}

 */




/* .centerdock { 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */



/* ul{
  text-align: justify;
}

ul::after {
  content: '';
  width: 100%; 
  display: inline-block;
}





apl

asciiArmor

asterisk

brainfuck

c

ceylon

clojure

cmake

cobol

coffeescript

commonLisp

cpp

crystal

csharp

css

cypher

d

dart

diff

dockerfile

dtd

dylan

ebnf

ecl

eiffel

elm

erlang

factor

fcl

forth

fortran

gas

gherkin

go

groovy

haskell

haxe

html

http

idl

java

javascript

jinja2

json

jsx

julia

kotlin

less

lezer

livescript

lua

markdown

mathematica

mbox

mirc

modelica

mscgen

mumps

mysql

nesC

nginx

nsis

ntriples

objectiveC

objectiveCpp

octave

oz

pascal

perl

pgsql

php

pig

powershell

properties

protobuf

puppet

python

q

r

ruby

rust

sas

sass

scala

scheme

shader

shell

sieve

smalltalk

solr

sparql

spreadsheet

sql

squirrel

stex

stylus

swift

tcl

textile

tiddlyWiki

tiki

toml

troff

tsx

ttcn

turtle

typescript

vb

vbscript

velocity

verilog

vhdl

wast

webIDL

xQuery

xml

yacas

yaml

z80


 */

 /* CAT */
 .wrapper {
  /* position: absolute;
  height: calc(0);
  width: 100%;
  top: 0; */
  z-index: -20;
  pointer-events: none;
  transform: scaleY(-1) translate(0px,73px); /* flip upside down */
}

.ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  background-color: black;
}
/* .cat_wrapper, .cat_wrapper.jump{
  transform:scale(50%); 
} */

.kitten{
  transform:scale(.5);  
  /* size:50%; */
  /* height:50%; */
}

@media screen and (max-width: 600px) {
  .mobile_spacer {
    visibility:visible;
    height: 10px;
  }
}

.cat {
  position: absolute;
  bottom: 65px;
  left: 100px;
  height: 30px;
  width: 60px;
  transition: 1.5s;
  transform-origin: center;
  background-color: transparent;
}

/* body */
.body {
  position: absolute;
  height: 30px;
  width: 60px;
}

.face_left .body { 
  animation: turn_body_left forwards 0.5s;
}

@keyframes turn_body_left {
  0%,100% { transform: scale(1); }
  50% { transform: scale(0.5, 1); }
}

.face_right .body {
  animation: turn_body_right forwards 0.5s;
}

@keyframes turn_body_right {
  0%,100% { transform: scale(1); }
  50% { transform: scale(0.5, 1); }
}

/* head */
.cat_head {
  position: absolute;
  height: 40px;
  width: 48px;
  right: -10px;
  top: -30px;
  transition: 0.5s;
  z-index: 50;
}

.first_pose .cat_head,
.face_left .cat_head{ 
  right: 22px;
}


/* tail */
.tail {
  position: absolute;
  top: -25px;
  height: 36px;
  width: 15px;
  animation: tail_motion forwards 2s;
  transform-origin: bottom right;
}

@keyframes tail_motion {
  0%,100% { 
    left: -5px;
    transform: rotate(0deg) scale(1); 
  }
  50% { 
    left: -10px;
    transform: rotate(-50deg) scale(-1,1); 
  }
}

.first_pose .tail ,
.face_left .tail {
  left: 45px;
  animation: tail_motion_alt forwards 2s;
}

@keyframes tail_motion_alt {
  0%,100% { 
    left: 45px;
    transform: rotate(0deg) scale(1); 
  }
  50% { 
    left: 40px;
    transform: rotate(50deg) scale(-1,1); 
  }
}



/* legs */
.leg {
  position: absolute;
  height: 20px;
  width: 10px;
  transform-origin: top center;
}

.front_legs,
.back_legs {
  position: absolute;
  height: 30px;
  transition: 0.7s;
}

.front_legs {
  width: 30px;
  right: 0;
}

.back_legs {
  width: 25px;
  left: 0; 
}

.face_left .leg svg {
  transform: scale(-1,1);
}

.face_right .front_legs{ right: 0; }

.first_pose .front_legs,
.face_left .front_legs{ right: 30px; }

.face_right .back_legs{ left: 0; }

.first_pose .back_legs,
.face_left .back_legs{ left: 35px; }

.one,
.three  {
  bottom: -15px;
  right: 0;
}

.two, 
.four {
  bottom: -15px;
  left: 0px;
}

.one.walk, 
.three.walk {
  animation: infinite 0.3s walk;
}

.two.walk, 
.four.walk {
  animation: infinite 0.3s walk_alt;
}

@keyframes walk {
  0%,100% {transform: rotate(-10deg);}
  50% {transform: rotate(10deg);}
}

@keyframes walk_alt {
  0%,100% {transform: rotate(10deg);}
  50% {transform: rotate(-10deg);}
}


/* jump */
.cat_wrapper {
  position: absolute;
  bottom: 0;
}

.cat_wrapper.jump .one { 
  animation: infinite 0.3s walk;
}

.cat_wrapper.jump .two { 
  animation: infinite 0.3s walk_alt;
}

.cat_wrapper.jump .three,
.cat_wrapper.jump .four {
  animation: none;
}

.cat_wrapper.jump .cat.face_right .back_legs {
  transform-origin: center;
  transform: rotate(50deg);
}

.cat_wrapper.jump .cat.face_left .back_legs {
  transform-origin: center;
  transform: rotate(-50deg);
}

.cat_wrapper.jump .cat.face_right .front_legs {
  transform-origin: center;
  transform: rotate(-60deg);
}

.cat_wrapper.jump .cat.face_left .front_legs {
  transform-origin: center;
  transform: rotate(60deg);
}

.cat_wrapper.jump{
  animation: jump forwards 1s;
}

@keyframes jump {
  0%, 100%  {bottom: 0px;}
  50% {bottom: 200px;}
}

.jump .face_left{ 
  animation: forwards 0.5s body_stand_left;
  transform-origin: right bottom;
}

.jump .face_right{ 
  animation: forwards 0.5s body_stand_right;
  transform-origin: left bottom;
}

@keyframes body_stand_right {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-45deg);}
}

@keyframes body_stand_left {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(45deg);}
}

svg {
  height: 100%;
  width: 100%;
}

polygon.eyes {
  fill: #ecbba3;
}

/* controls cat color   */
polygon, path  {
  /* fill:red; */
  fill: rgb(245,245,245);
}

/* fix dropdown arrows */
svg.css-tj5bde-Svg > path{
  fill:rgb(205,205,205);
}

.sign {
  position: absolute;
  color: white;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}